import { AxiosPromise } from 'axios'
import { http } from '@/api/requests/httpAxios'

export default class ProjectTasksApi {
  public static getTasks(projectId: number): AxiosPromise {
    return http.get(`/project/${projectId}/tasks`)
  }
  public static deleteTask(taskId: number): AxiosPromise {
    return http.delete(`/task/${taskId}`)
  }
  public static updateTask(
    projectId: number,
    taskId: number,
    data: any
  ): AxiosPromise {
    return http.put(`/project/${projectId}/task/${taskId}`, data)
  }
  public static changeTaskStatus(
    projectId: number,
    taskId: number,
    status: string
  ): AxiosPromise {
    return http.put(`/project/${projectId}/task/${taskId}`, { status })
  }

  public static createTask(projectId: number, data: any): AxiosPromise {
    return http.post(`/project/${projectId}/task/new`, data)
  }
}
