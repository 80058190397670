


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class TabNavigation extends Vue {
  @Prop(Array) readonly tabs!: any[]
  @Prop({ default: 'tab', type: String }) readonly tabQueryName!: string
  @Prop({ default: false }) readonly vertical?: any
  @Prop({ default: false }) readonly pills?: any

  private tabIndex = 0

  private isDisplayTabs = true

  get tIndex() {
    return this.tabIndex
  }

  set tIndex(index: number) {
    const tab = this.getTabIndexQuery
    if (tab !== index || tab !== this.tabIndex) {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            [this.tabQueryName]: this.tabs[index].name
          }
        })
        .catch(() => undefined)
    }
    this.$emit('tabChanged', this.tabs[index])
  }

  private get getTabIndexQuery() {
    const tabValue = this.$route.query[this.tabQueryName]
    if (tabValue) {
      const tab = this.tabs.findIndex((t: any) => t.name === tabValue)
      if (tab > -1) {
        return tab
      }
    }
    return null
  }

  @Watch('getTabIndexQuery')
  private tabChangedQuery(tab: number | null) {
    if (!_.isNil(tab)) {
      this.tIndex = tab
    }
    this.isDisplayTabs = this.tabs.filter((t: any) => t.isDisplay).length > 1
  }

  private created() {
    const queryIndex = this.getTabIndexQuery
    const firstDisplay = this.tabs.findIndex((t: any) => t.isDisplay)
    const firstAllowed = firstDisplay !== -1 ? firstDisplay : 0

    this.tabIndex = !_.isNil(queryIndex) ? queryIndex : firstAllowed

    this.$emit('tabChanged', this.tabs[this.tabIndex])
    this.isDisplayTabs = this.tabs.filter((t: any) => t.isDisplay).length > 1
  }

  private beforeDestroy() {
    this.$router
      .push({
        query: {
          ...this.$route.query,
          [this.tabQueryName]: undefined
        }
      })
      .catch(() => undefined)
  }
}
