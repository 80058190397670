
































import {
  Component,
  InjectReactive,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import { FormSchema } from 'vue-form-generator'
import _ from 'lodash'
import AvatarRenderer from '@/components/table/AvatarRenderer.vue'
import dateFormat from '@/config/dateFormat'
import moment from 'moment'
import { validateResultsToText, validateSchemaSync } from '@/utils/validation'
import validators from '@/utils/validators'
import { ProjectUsersDataSource } from '@/components/projects/project-page/helpers/datasource'
import { applicationStatus } from '@/config/projects/applicationStatus'

@Component({
  components: {
    AvatarRenderer
  }
})
export default class TaskCardModal extends Vue {
  @InjectReactive() project!: any
  @Prop(String) readonly mode!: string
  @Prop(String) readonly modalId!: string

  private model: any = {}
  private schema_: FormSchema = { fields: [] }
  private projectId!: number

  private errorText: string[] = []

  @Watch('project', { deep: true })
  private onProjectChanged() {
    this.schema_.fields!.find(
      f => f.model === 'users'
    )!.dataSource = new ProjectUsersDataSource(this.getProjectUsers())
  }

  private getProjectUsers() {
    const users = this.project.users || []
    return users.filter(
      (user: any) =>
        user.ProjectParticipant.applicationStatus == applicationStatus.accepted
    )
  }

  private get title() {
    switch (this.mode) {
      case 'edit': {
        return 'Редактирование задачи'
      }
      case 'add': {
        return 'Добавление задачи'
      }
      default: {
        return 'Просмотр задачи'
      }
    }
  }

  public setTask(data: any) {
    const postData = _.cloneDeep(data)
    if (data.deadline) {
      postData.deadline = moment(
        data.deadline,
        dateFormat.date.serverFormat
      ).format(dateFormat.date.localFormat)
    }
    this.model = postData
  }

  private onSave(e: any) {
    this.errorText = validateResultsToText(
      validateSchemaSync(this.schema_, this.model)
    )

    if (!_.isEmpty(this.errorText)) {
      e.preventDefault()
      return
    }

    const postData = {
      ...this.model,
      deadline: this.model.deadline
        ? moment(this.model.deadline, dateFormat.date.localFormat)
            .utcOffset(0)
            .format(dateFormat.date.serverFormat)
        : null
    }
    this.$emit('save', postData)
  }
  private onDeleteTask() {
    this.$emit('delete', this.model)
  }

  private static schema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'text',
        label: 'Название задачи',
        model: 'title',
        required: true,
        validator: validators.required,
        styleClasses: 'wm-100',
        hint: 'Например, "Создание ER-диаграммы базы данных"'
      },
      {
        type: 'CKEditor',
        label: 'Описание',
        model: 'description',
        required: true,
        validator: validators.required,
        hint: ''
      },
      {
        type: 'datepicker',
        label: 'Срок',
        model: 'deadline',
        styleClasses: 'wm-50 pr-2'
      },
      {
        type: 'headerSeparator',
        text: '',
        model: 'empty',
        hasHR: true,
        styleClasses: 'wm-100'
      },
      {
        type: 'userPicker',
        label: 'Участники',
        model: 'users',
        placeholder: 'Не выбран',
        hint: 'У задачи должен быть исполнитель',
        isMultiple: true
      }
    ]
  }

  private async created() {
    this.projectId = parseInt(this.$route.params.id.toString())

    Vue.set(this, 'schema_', _.cloneDeep(TaskCardModal.schema))
    this.schema_.fields!.find(
      f => f.model === 'users'
    )!.dataSource = new ProjectUsersDataSource(this.getProjectUsers())
    for (const field of this.schema_!.fields ?? []) {
      field.disabled = this.mode === 'read' ? true : field.disabled
    }
  }
}
