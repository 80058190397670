










import { Component, Vue, Prop } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { GridApi, GridOptions } from 'ag-grid-community'
import AgGridFactory from '@/factories/agGridFactory'
import ProjectFileRenderer from '@/components/table/ProjectFileRenderer.vue'

@Component({
  components: { AgGridVue, ProjectFileRenderer }
})
export default class ProjectViewFiles extends Vue {
  @Prop() private readonly files!: any[]

  private gridOptions: GridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    domLayout: 'autoHeight',
    columnDefs: [
      {
        cellRendererFramework: ProjectFileRenderer,
        headerName: 'Название',
        field: 'title',
        tooltipField: 'title',
        width: 120
      },

      {
        headerName: 'Описание',
        field: 'shortDescription'
      }
    ],
    defaultColDef: {
      resizable: false,
      sortable: false
    },
    headerHeight: 35,
    onGridReady: this.onGridReady
  }

  private onGridReady({ api }: { api: GridApi }) {
    api.sizeColumnsToFit()
  }
}
