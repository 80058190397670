











































import { AgGridVue } from 'ag-grid-vue'
import { Vue, Component, InjectReactive, Watch } from 'vue-property-decorator'
import { GridOptions } from 'ag-grid-community'
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue'
import AgGridFactory from '@/factories/agGridFactory'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import LoadFileButton from '@/components/ui/LoadFileButton.vue'
import AvatarRenderer from '@/components/table/AvatarRenderer.vue'
import ApplicationStatusRenderer from '@/components/table/ApplicationStatusRenderer.vue'
import { toastMapper } from '@/store/modules/toast'
import ProjectsAPI from '@/components/projects/common/helpers/requests'
import { applicationStatus } from '@/config/projects/applicationStatus'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: {
    AgGridVue,
    ActionRenderer,
    ConfirmationModal,
    LoadFileButton
  }
})
export default class ProjectEditApplications extends Mapper {
  @InjectReactive() project!: any

  @Watch('project', { deep: true })
  private async onProjectChanged() {
    await this.getApplications()
  }

  private selectedApplication: any = {}
  private rowData: any = []
  private gridOptions: GridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    domLayout: 'autoHeight',
    onGridReady: this.onProjectChanged,
    columnDefs: [
      {
        cellRendererFramework: AvatarRenderer,
        headerName: 'Аватар',
        field: 'user.avatarUrl',
        tooltipField: 'avatar',
        suppressSizeToFit: true,
        width: 100,
        filter: false,
        sortable: false
      },
      {
        headerName: 'Инициалы',
        field: 'user.initials'
      },
      {
        headerName: 'Организация',
        field: 'user.organizations',
        valueFormatter(params: any) {
          if (params.value) {
            params.value = params.value.map((r: any) => r.title)
          }
          return params.value ? params.value.join(', ') : null
        }
      },
      {
        headerName: 'Комментарий',
        field: 'comment'
      },
      {
        cellRendererFramework: ApplicationStatusRenderer,
        headerName: 'Статус заявки',
        field: 'applicationStatus',
        filter: false,
        sortable: false
      },
      {
        ...AgGridFactory.getActionColumn({
          cellRendererParams: {
            onConfirm: this.onConfirm,
            onConfirmText: 'Подтвердить заявку',
            onDelete: this.onDelete,
            onDeleteText: 'Отклонить заявку',
            onProfile: this.onProfile,
            onProfileDisabled: () =>
              !PermissionManager.common().can(
                permissions.viewProfile,
                undefined
              )
          },
          minWidth: 160,
          maxWidth: 160
        })
      }
    ]
  }

  private async rejectApplication() {
    await ProjectsAPI.editApplication(this.selectedApplication.id, {
      applicationStatus: applicationStatus.rejected,
      comment: this.selectedApplication.comment || ''
    })
    this.getApplications()
  }

  private onDelete(params: any) {
    this.selectedApplication = params.node.data
    this.$bvModal.show('rejectApplicationModal')
  }

  private async acceptApplication() {
    await ProjectsAPI.editApplication(this.selectedApplication.id, {
      applicationStatus: applicationStatus.accepted
    })
    this.getApplications()
  }

  private onConfirm(params: any) {
    this.selectedApplication = params.node.data
    this.$bvModal.show('acceptApplicationModal')
  }

  private onProfile(params: any) {
    this.$router.push(`/profile/${params.node.data.userId}`)
  }

  private async getApplications() {
    if (this.project.id) {
      this.rowData = (
        await ProjectsAPI.getProjectApplications({
          projectId: this.project.id,
          applicationStatus: [
            applicationStatus.rejected,
            applicationStatus.toCheck
          ]
        })
      ).data.projectParticipants
      this.gridOptions.api?.setRowData(this.rowData)
    }
  }
}
