



















import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import { baseURL } from '@/api/requests/httpAxios'

@Component
export default class ProjectFileRenderer extends Vue {
  params!: ICellRendererParams
  private baseURL = baseURL
  private defaultIcon = 'file'
  private icons: any = {
    xls: 'file-excel',
    pdf: 'file-pdf',
    docx: 'file-word',
    png: 'image'
  }

  private get icon() {
    const extension =
      this.params.value
        .split('.')
        .pop()
        .trim() || ''
    return Object.keys(this.icons).includes(extension)
      ? this.icons[extension]
      : this.defaultIcon
  }
}
