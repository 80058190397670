




import { Component, Vue } from 'vue-property-decorator'
import ProjectPage from '@/components/projects/project-page/index.vue'

@Component({ components: { ProjectPage } })
export default class ProjectPageRoute extends Vue {}
