














































































import { Component, Vue, InjectReactive } from 'vue-property-decorator'
import { imagePreview } from '@/config/default/defaultImages'
import { projectStatusTranslation } from '@/config/projects/status'
import ProjectViewFiles from '@/components/projects/project-page/components/project-view/components/ProjectViewFiles.vue'
import ProjectsAPI from '@/components/projects/common/helpers/requests'
import { projectRoles } from '@/config/projects/projectRoles'
import { applicationStatus } from '@/config/projects/applicationStatus'
import { userMapper } from '@/store/modules/user'
import { nestedEmitter } from '@/utils/emitter'
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser'])
  }
})

@Component({
  components: { ProjectViewFiles, ConfirmationModal }
})
export default class ProjectViewComponent extends Mappers {
  @InjectReactive() project!: any

  private projectStatusTranslation_ = projectStatusTranslation
  private imagePreview_ = imagePreview

  private get isAuthor() {
    const user = this.project?.users?.find(
      (user: any) => user.id == this.currentUser.id
    )?.ProjectParticipant
    return user?.projectRoles.includes(projectRoles.author)
  }

  private get isApplicationSubmitted() {
    return this.project?.users?.find(
      (user: any) => user.id == this.currentUser.id
    )
  }

  private async submitApplication() {
    await ProjectsAPI.submitApplication(this.project.id, this.currentUser.id, {
      projectRoles: [projectRoles.participant],
      applicationStatus: applicationStatus.toCheck
    })
    nestedEmitter(this.$parent, 'updateProject')
  }

  private async rejectApplication() {
    const application = this.project?.users?.find(
      (user: any) => user.id == this.currentUser.id
    ).ProjectParticipant
    await ProjectsAPI.deleteApplication(application.id)
    nestedEmitter(this.$parent, 'updateProject')
  }
}
