







































import { Component, Prop, Vue } from 'vue-property-decorator'
import UsersTable from '@/components/admin/UsersTable.vue'
import { IDatasource } from 'ag-grid-community'
import _ from 'lodash'
import { getUserText } from '@/utils/formatters'

@Component({ components: { UsersTable } })
export default class PickUser extends Vue {
  @Prop(String) readonly modalId!: string
  @Prop(Object) readonly datasource!: IDatasource
  @Prop({ type: Boolean, default: true }) readonly withRoles?: boolean
  @Prop({ type: Array, default: () => [] }) readonly banRoles!: string[]

  private selectedUser: any = null
  private errorText = ''

  private onEdit(params: any) {
    this.selectedUser = params.data
    const ban = _.intersection(this.banRoles, this.selectedUser?.roles ?? [])
    this.errorText = !_.isEmpty(ban)
      ? 'Извините, вы не можете выбрать пользователя с данной ролью'
      : ''
  }

  private get userInitials() {
    return getUserText(this.selectedUser)
  }

  private onPickUser() {
    this.$emit('pick', this.selectedUser)
  }
  private onHidden() {
    this.selectedUser = null
  }

  private getSelectedText() {
    return !this.selectedUser
      ? 'Пользователь не выбран'
      : 'Выбран пользователь:'
  }
}
