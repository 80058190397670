











import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import {
  applicationStatus,
  applicationStatusTranslation
} from '@/config/projects/applicationStatus'

@Component
export default class ApplicationStatusRenderer extends Vue {
  params!: ICellRendererParams
  private applicationStatus = applicationStatus
  private applicationStatusTranslation = applicationStatusTranslation
}
