








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KanbanBadge extends Vue {
  @Prop({ default: 'teal', type: String }) readonly color!: string
}
