















import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import {
  projectRoles,
  projectRolesTranslation,
  projectRolesBadges
} from '@/config/projects/projectRoles'

@Component
export default class MemberRolesRenderer extends Vue {
  params!: ICellRendererParams

  private projectRoles = projectRoles
  private projectRolesTranslation = projectRolesTranslation
  private projectRolesBadges = projectRolesBadges
}
