import DataSource from '@/api/datasource/datasource'
import { IGetRowsParams } from 'ag-grid-community'
import { User } from '@/types/interfaces/user'

export class ProjectUsersDataSource extends DataSource {
  private readonly rowData: User[]

  constructor(rowData: User[]) {
    super()
    this.rowData = rowData
  }

  getRows(params: IGetRowsParams) {
    params.successCallback(this.rowData, this.rowData.length)
  }
}
