












































import Badge from './Badge.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import dateFormat from '@/config/dateFormat'
import moment from 'moment'
import { userMapper } from '@/store/modules/user'
import { defaultProfile } from '@/config/default/defaultImages'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser'])
  }
})

@Component({ components: { Badge } })
export default class TaskCard extends Mappers {
  @Prop({ type: Object, default: () => ({}) }) readonly task!: any

  private defaultProfile_ = defaultProfile

  private maxUsersToDisplay = 3

  private get taskUsers() {
    return this.task.users.slice(0, this.maxUsersToDisplay)
  }

  private get taskTooltip() {
    return this.task.users.slice(3).map((user: any) => user.initials)
  }

  private onClickCard() {
    this.$emit('open', this.task)
  }

  private beautifyDate(date: string) {
    return moment(date)
      .locale('ru')
      .format(dateFormat.date.day)
  }

  private getVariantDate(date: string) {
    const diff = moment(date).diff(moment(), 'days')
    if (diff <= 0) {
      return 'danger'
    }
    if (diff > 0 && diff <= 2) {
      return 'secondary'
    }
    return 'info'
  }
}
