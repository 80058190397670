import { render, staticRenderFns } from "./ProjectEditStatus.vue?vue&type=template&id=40ea1c72&scoped=true&"
import script from "./ProjectEditStatus.vue?vue&type=script&lang=ts&"
export * from "./ProjectEditStatus.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectEditStatus.vue?vue&type=style&index=0&id=40ea1c72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ea1c72",
  null
  
)

export default component.exports