






import { Component, Vue, InjectReactive } from 'vue-property-decorator'
import ProjectUpsertComponent from '@/components/projects/common/ProjectUpsertComponent.vue'
import ProjectsAPI from '@/components/projects/common/helpers/requests'
import { toastMapper } from '@/store/modules/toast'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: { ProjectUpsertComponent }
})
export default class ProjectEditCommon extends Mapper {
  @InjectReactive() project!: any

  private async onSave(model: any) {
    for (const key of Object.keys(model)) {
      this.project[key] = model[key]
    }

    await ProjectsAPI.editProject(this.project.id, model)

    this.pushToast({
      time: 5,
      title: 'Редактирование проекта',
      message: `Проект <strong>${this.project.title}</strong> успешно обновлен`
    })
  }
}
