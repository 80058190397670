export enum taskStatus {
  'draft' = 'draft', // Не назначенные
  'todo' = 'todo', // Поставлена
  'doing' = 'doing', // Принята к исполнению
  'check' = 'check', // На проверке
  'done' = 'done' // Принята
}

export const taskStatusTranslation: { [k: string]: string } = {
  [taskStatus.draft]: 'Не назначенные',
  [taskStatus.todo]: 'Поставлена',
  [taskStatus.doing]: 'Принята к исполнению',
  [taskStatus.check]: 'На проверке',
  [taskStatus.done]: 'Принята'
}
