







































import { AgGridVue } from 'ag-grid-vue'
import { Vue, Component, InjectReactive, Watch } from 'vue-property-decorator'
import { GridOptions } from 'ag-grid-community'
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue'
import AgGridFactory from '@/factories/agGridFactory'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import LoadFileButton from '@/components/ui/LoadFileButton.vue'
import AvatarRenderer from '@/components/table/AvatarRenderer.vue'
import PickUser from '@/components/common/modal/PickUser.vue'
import { AdminUsersDataSource } from '@/api/datasource/admin/adminUsers'
import {
  projectRoles,
  projectRolesTranslation
} from '@/config/projects/projectRoles'
import { toastMapper } from '@/store/modules/toast'
import MemberRolesRenderer from '@/components/table/MemberRolesRenderer.vue'
import ProjectsAPI from '@/components/projects/common/helpers/requests'
import { applicationStatus } from '@/config/projects/applicationStatus'
import { nestedEmitter } from '@/utils/emitter'
import SetFilter from '@/components/table/filters/SetFilter'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: {
    AgGridVue,
    ActionRenderer,
    ConfirmationModal,
    LoadFileButton,
    PickUser,
    SetFilter
  }
})
export default class ProjectEditMembers extends Mapper {
  @InjectReactive() project!: any

  @Watch('project', { deep: true })
  private async onProjectChanged() {
    await this.getApplications()
  }

  private dataSource: AdminUsersDataSource = new AdminUsersDataSource()
  private selectedMember: any = {}
  private rowData: any = []

  private gridOptions: GridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    domLayout: 'autoHeight',
    paginationPageSize: 10,
    pagination: true,
    onGridReady: this.onGridReady,
    frameworkComponents: { SetFilter },
    columnDefs: [
      {
        cellRendererFramework: AvatarRenderer,
        headerName: 'Аватар',
        field: 'user.avatarUrl',
        tooltipField: 'avatar',
        suppressSizeToFit: true,
        width: 100,
        filter: false,
        sortable: false
      },
      {
        headerName: 'Инициалы',
        field: 'user.initials'
      },
      {
        headerName: 'Организация',
        field: 'user.organizations',
        valueGetter(params: any) {
          return params.data.user.organizations
            .map((r: any) => r.title)
            .join(', ')
        }
      },
      {
        cellRendererFramework: MemberRolesRenderer,
        headerName: 'Роли в проекте',
        field: 'user.projectRoles',
        filter: 'SetFilter',
        filterParams: {
          filterKeys: Object.keys(projectRoles),
          keyFormatter(key: string) {
            return projectRolesTranslation[key]
          }
        },
        valueGetter(params: any) {
          return params.data.projectRoles ?? []
        }
      },
      {
        ...AgGridFactory.getActionColumn({
          cellRendererParams: {
            onDelete: this.onDelete,
            onProfile: this.onProfile,
            onProfileDisabled: () =>
              !PermissionManager.common().can(
                permissions.viewProfile,
                undefined
              ),
            onArrowUp: this.setLeader,
            onArrowDown: this.unsetLeader,
            onDeleteDisabled: this.onDeleteDisabled,
            onArrowUpHidden: (params: any) => this.isLeaderHidden(params),
            onArrowDownHidden: (params: any) => !this.isLeaderHidden(params),
            onArrowDownDisabled: () => !this.leaders()
          },
          minWidth: 155,
          maxWidth: 155
        })
      }
    ]
  }

  private onGridReady() {
    this.onProjectChanged()
  }

  private isLeaderHidden(params: any) {
    return params.node.data
      ? params.node.data.projectRoles.includes(projectRoles.leader)
      : true
  }

  private leaders() {
    const users = (this.project?.users ?? []).filter(
      (u: any) =>
        u.ProjectParticipant.applicationStatus == applicationStatus.accepted &&
        u.ProjectParticipant.projectRoles.includes(projectRoles.leader)
    )
    return users.length > 1
  }

  private onDeleteDisabled(params: any) {
    return params.node.data
      ? params.node.data.projectRoles.includes(projectRoles.author) ||
          params.node.data.projectRoles.includes(projectRoles.leader)
      : true
  }

  private async deleteMember() {
    await ProjectsAPI.editApplication(this.selectedMember.id, {
      applicationStatus: applicationStatus.rejected
    })

    this.pushToast({
      time: 5,
      title: 'Удаление участника',
      message: `<strong>${this.selectedMember.user.initials}</strong> удален из списка участников`
    })
    nestedEmitter(this.$parent, 'updateProject')
  }

  private onDelete(params: any) {
    this.selectedMember = params.node.data
    this.$bvModal.show('deleteMemberModal')
  }

  private onProfile(params: any) {
    this.$router.push(`/profile/${params.node.data.userId}`)
  }

  private async setLeader(params: any) {
    this.selectedMember = params.data
    const newLeaderRoles = this.selectedMember.projectRoles || []
    newLeaderRoles.push(projectRoles.leader)
    await ProjectsAPI.editApplication(this.selectedMember.id, {
      projectRoles: newLeaderRoles
    })

    nestedEmitter(this.$parent, 'updateProject')
  }

  private async unsetLeader(params: any) {
    this.selectedMember = params.data
    await ProjectsAPI.editApplication(this.selectedMember.id, {
      projectRoles: this.selectedMember.projectRoles.filter(
        (role: string) => role != projectRoles.leader
      )
    })
    nestedEmitter(this.$parent, 'updateProject')
  }

  private async onPickUser(user: any) {
    const projectUser = this.project.users.find((u: any) => u.id == user.id)
    if (!projectUser) {
      await ProjectsAPI.submitApplication(this.project.id, user.id, {
        projectRoles: [projectRoles.participant],
        applicationStatus: applicationStatus.accepted
      })
      nestedEmitter(this.$parent, 'updateProject')
    } else {
      this.pushToast({
        time: 5,
        title: 'Приглашение участника',
        message: `<strong>${user.initials}</strong> уже присутствует в списке участников`
      })
    }
  }

  private async getApplications() {
    if (this.project.id) {
      this.rowData = (
        await ProjectsAPI.getProjectApplications({
          projectId: this.project.id,
          applicationStatus: applicationStatus.accepted
        })
      ).data.projectParticipants
      this.gridOptions.api?.setRowData(this.rowData)
    }
  }
}
