

































import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator'
import Kanban from '@/components/ui/kanban/Kanban.vue'
import _ from 'lodash'
import { taskStatus, taskStatusTranslation } from '@/config/projects/taskStatus'
import TaskCardModal from '@/components/projects/project-page/components/project-edit/components/TaskCardModal.vue'
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue'
import { toastMapper } from '@/store/modules/toast'
import ProjectTasksApi from '@/components/projects/project-page/helpers/requests/tasks'
import { permissionsProject } from '@/api/permissions/ProjectPermissions'

const Mappers = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: { Kanban, TaskCardModal, ConfirmationModal }
})
export default class ProjectKanban extends Mappers {
  @InjectReactive() permissions!: any
  @InjectReactive() project!: any

  private deletedTask: any = null

  $refs!: {
    taskCardEditModal: TaskCardModal
    taskCardAddModal: TaskCardModal
  }

  private get isDraggable() {
    return this.permissions.can(permissionsProject.moveTasks, undefined)
  }

  private get canEdit() {
    return this.permissions.can(permissionsProject.editTasks, undefined)
  }

  private columns: any[] = []

  private projectId!: number

  @Watch('canEdit')
  onCanEditChanged() {
    this.reloadTasks()
  }

  @Watch('isDraggable')
  onIsDraggableChanged() {
    this.reloadTasks()
  }

  private onOpenTask(task: any) {
    this.$refs.taskCardEditModal.setTask(task)
    this.$bvModal.show('taskCardEditModal')
  }

  private onAddTask(status: string) {
    this.$refs.taskCardAddModal.setTask({ status })
    this.$bvModal.show('taskCardAddModal')
  }

  private onDelete(task: any) {
    this.deletedTask = task
    this.$bvModal.show('deleteTask')
  }

  private async onDeleteConfirm() {
    if (this.deletedTask?.id) {
      await ProjectTasksApi.deleteTask(this.deletedTask.id)
      this.$bvModal.hide('taskCardEditModal')
      const message = `Выполнено удаление задачи <strong>"${_.clone(
        this.deletedTask.title
      )}"</strong>`
      this.pushToast({
        title: 'Задачи',
        message
      })
      this.deletedTask = null
      await this.reloadTasks()
    }
  }

  private async onChangeStatus(e: any) {
    e.element.status = e.to
    await ProjectTasksApi.changeTaskStatus(this.projectId, e.element.id, e.to)
  }

  private async onEditTask(task: any) {
    const postData = task
    postData.users = (postData.users ?? []).map((u: any) => u.id)
    await ProjectTasksApi.updateTask(this.projectId, postData.id, postData)
    await this.reloadTasks()
  }

  private async onSaveTask(task: any) {
    const postData = task
    postData.users = (postData.users ?? []).map((u: any) => u.id)

    await ProjectTasksApi.createTask(this.projectId, postData)
    await this.reloadTasks()
  }

  private async reloadTasks() {
    const response = await ProjectTasksApi.getTasks(this.projectId)
    const tasks = _.groupBy(response.data.tasks, 'status')

    this.columns = []

    for (const [status, translatedStatus] of Object.entries(
      taskStatusTranslation
    )) {
      this.columns.push({
        title: translatedStatus,
        status,
        isDraggable: this.isDraggable,
        canAdd: status === taskStatus.draft && this.canEdit,
        tasks: status in tasks ? tasks[status] : []
      })
    }
  }

  private async created() {
    this.projectId = parseInt(this.$route.params.id.toString())

    await this.reloadTasks()
  }
}
