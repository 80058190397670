












import { Component, Vue, ProvideReactive } from 'vue-property-decorator'
import TabNavigation from '@/components/ui/TabNavigation.vue'
import ProjectViewComponent from '@/components/projects/project-page/components/project-view/index.vue'
import ProjectEditComponent from '@/components/projects/project-page/components/project-edit/index.vue'
import ProjectKanbanComponent from '@/components/projects/project-page/components/project-edit/components/ProjectKanban.vue'
import ProjectEditMembers from '@/components/projects/project-page/components/project-edit/components/ProjectEditMembers.vue'
import ProjectEditApplications from '@/components/projects/project-page/components/project-edit/components/ProjectEditApplications.vue'
import {
  ProjectPermissions,
  permissionsProject
} from '@/api/permissions/ProjectPermissions'
import ProjectsAPI from '../common/helpers/requests'
import _ from 'lodash'

@Component({
  components: { ProjectViewComponent, TabNavigation }
})
export default class ProjectPage extends Vue {
  @ProvideReactive() project: any = {}
  @ProvideReactive() permissions!: any

  private isLoaded = false

  private tabs = [
    {
      name: 'view',
      isDisplay: true,
      title: 'Просмотр',
      component: ProjectViewComponent
    },
    {
      name: 'edit',
      isDisplay: true,
      title: 'Редактирование',
      component: ProjectEditComponent
    },
    {
      name: 'tasks',
      isDisplay: true,
      title: 'Задачи',
      component: ProjectKanbanComponent
    },
    {
      name: 'applications',
      isDisplay: true,
      title: 'Заявки',
      component: ProjectEditApplications
    },
    {
      name: 'members',
      isDisplay: true,
      title: 'Участники',
      component: ProjectEditMembers
    }
  ]

  private async getProject() {
    const projectId = Number(this.$route.params.id)
    if (!_.isNaN(projectId)) {
      this.project = (await ProjectsAPI.getProject(projectId)).data.project
      if (!this.project) {
        this.$router.replace('/error')
      }
    } else {
      this.$router.replace('/error')
    }
  }

  private async mounted() {
    const getTabByName = (name: string) =>
      this.tabs.find((t: any) => t.name === name)

    await this.getProject()

    this.permissions = ProjectPermissions.project(this.project)

    getTabByName('view')!.isDisplay = this.permissions.can(
      permissionsProject.viewProject,
      undefined
    )

    getTabByName('tasks')!.isDisplay = this.permissions.can(
      permissionsProject.viewProject,
      undefined
    )

    getTabByName('edit')!.isDisplay = this.permissions.can(
      permissionsProject.editProject,
      undefined
    )

    getTabByName('applications')!.isDisplay = this.permissions.can(
      permissionsProject.editProject,
      undefined
    )

    getTabByName('members')!.isDisplay = this.permissions.can(
      permissionsProject.editProject,
      undefined
    )

    this.isLoaded = true
  }
}
