






































































import { Component, InjectReactive, Vue } from 'vue-property-decorator'
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue'
import {
  projectStatus,
  projectStatusDescription,
  projectStatusTranslation
} from '@/config/projects/status'
import ProjectsAPI from '@/components/projects/common/helpers/requests'
import { toastMapper } from '@/store/modules/toast'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({ components: { ConfirmationModal } })
export default class ProjectEditStatus extends Mapper {
  @InjectReactive() project!: any

  private projectStatusTranslation_ = projectStatusTranslation

  private newStatus = ''

  private projectStatuses = Object.values(projectStatus).map(status => ({
    type: status,
    translated: projectStatusTranslation[status],
    text: projectStatusDescription[status]
  }))

  private editStatus(newStatus: any) {
    this.newStatus = newStatus
    this.$bvModal.show('confirmStatusEdit')
  }

  private confirmDelete() {
    this.$bvModal.show('confirmDeleteProject')
  }

  private async onEditStatus() {
    await ProjectsAPI.changeStatus(
      this.project.id,
      this.newStatus as projectStatus
    )

    this.pushToast({
      time: 5,
      title: 'Изменение статуса проекта',
      message: `Статус проекта <strong>${
        this.project.title
      }</strong> успешно обновлен на <strong>${
        projectStatusTranslation[this.newStatus]
      }</strong>`
    })
    this.project.status = this.newStatus
  }

  private async onDeleteProject() {
    await ProjectsAPI.deleteProject(this.project.id)

    this.pushToast({
      time: 5,
      title: 'Удаление проекта',
      message: `Проект <strong>${this.project.title}</strong> успешно удален`
    })
    this.$router.push('/projects')
  }
}
