





































import { AgGridVue } from 'ag-grid-vue'
import { Vue, Component, InjectReactive } from 'vue-property-decorator'
import { GridOptions, ValueFormatterParams } from 'ag-grid-community'
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue'
import AgGridFactory from '@/factories/agGridFactory'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import dateFormat from '@/config/default/dateFormat'
import moment from 'moment'
import LoadFileButton from '@/components/ui/LoadFileButton.vue'
import { toastMapper } from '@/store/modules/toast'
import AddFileModal from '@/components/ui/AddFileModal.vue'
import ProjectsAPI from '@/components/projects/common/helpers/requests'
import { nestedEmitter } from '@/utils/emitter'
import ProjectFileRenderer from '@/components/table/ProjectFileRenderer.vue'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: {
    AgGridVue,
    ActionRenderer,
    ConfirmationModal,
    LoadFileButton,
    AddFileModal
  }
})
export default class ProjectEditFiles extends Mapper {
  @InjectReactive() project!: any

  private selectedFile: any = {}

  private gridOptions: GridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    domLayout: 'autoHeight',
    columnDefs: [
      {
        headerName: 'Название',
        cellRendererFramework: ProjectFileRenderer,
        field: 'title'
      },
      {
        headerName: 'Краткое описание',
        field: 'shortDescription'
      },
      {
        headerName: 'Дата обновления',
        field: 'updatedAt',
        valueFormatter(params: ValueFormatterParams) {
          return params.value
            ? moment(params.value).format(dateFormat.date.localFormat)
            : ''
        }
      },
      {
        ...AgGridFactory.getActionColumn({
          cellRendererParams: {
            onDelete: this.onDelete
          },
          minWidth: 100,
          maxWidth: 100
        })
      }
    ]
  }

  private async deleteFile() {
    await ProjectsAPI.deleteFileFromProject(this.selectedFile.id)
    this.pushToast({
      time: 5,
      title: 'Удаление файла',
      message: `Файл <strong>${this.selectedFile.title}</strong> успешно удален`
    })
    nestedEmitter(this.$parent, 'updateProject')
  }

  private onDelete(params: any) {
    this.selectedFile = params.data
    this.$bvModal.show('deleteFileModal')
  }

  private async addFile(fileModel: any) {
    await ProjectsAPI.addFileToProject(this.project.id, fileModel)
    nestedEmitter(this.$parent, 'updateProject')
  }
}
