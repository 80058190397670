































import { Vue, Component, Prop } from 'vue-property-decorator'
import { toastMapper } from '@/store/modules/toast'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component
export default class AddFileModal extends Mapper {
  @Prop() private readonly modalId!: string

  private fileModel: any = {}

  private resetModal() {
    this.fileModel = {
      file: null,
      title: '',
      shortDescription: '',
      type: 'file'
    }
  }

  private async onOk() {
    if (!this.fileModel.file) {
      this.pushToast({
        time: 5,
        title: 'Добавление файа',
        message: `Необходимо выбрать файл`
      })
    } else {
      this.fileModel.title = this.fileModel.file.name
      this.$emit('ok', this.fileModel)
      this.$bvModal.hide(this.modalId)
    }
  }
}
