























































import draggable from 'vuedraggable'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TaskCard from '@/components/ui/kanban/TaskCard.vue'
import _ from 'lodash'

@Component({ components: { TaskCard, draggable } })
export default class Kanban extends Vue {
  @Prop(Array) readonly columns!: any[]

  private onOpenCard(task: any) {
    this.$emit('open', task)
  }
  private onChat(user: any) {
    this.$emit('chat', user)
  }

  private swapTitle = ''
  private change(columnTitle: string, e: any) {
    if (e.added) {
      this.swapTitle = columnTitle
    } else if (e.removed) {
      this.$emit('drag', {
        element: e.removed?.element,
        to: _.clone(this.swapTitle),
        from: columnTitle
      })
      this.swapTitle = ''
    }
  }

  private onAdd(title: string) {
    this.$emit('add', title)
  }
}
