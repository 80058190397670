










import { Component, Vue, InjectReactive } from 'vue-property-decorator'
import TabNavigation from '@/components/ui/TabNavigation.vue'
import ProjectEditCommon from '@/components/projects/project-page/components/project-edit/components/ProjectEditCommon.vue'
import ProjectEditStatus from '@/components/projects/project-page/components/project-edit/components/ProjectEditStatus.vue'
import ProjectEditFiles from '@/components/projects/project-page/components/project-edit/components/ProjectEditFiles.vue'

@Component({
  components: { TabNavigation, ProjectEditCommon, ProjectEditFiles }
})
export default class ProjectEditComponent extends Vue {
  @InjectReactive() project!: any

  private tabs = [
    {
      name: 'common',
      isDisplay: true,
      title: 'Общее',
      component: ProjectEditCommon,
      icon: ['fa', 'pen']
    },
    {
      name: 'status',
      isDisplay: true,
      title: 'Статус проекта',
      component: ProjectEditStatus,
      icon: ['fas', 'exchange-alt']
    },
    {
      name: 'files',
      isDisplay: true,
      title: 'Файлы',
      component: ProjectEditFiles,
      icon: ['fas', 'file']
    }
  ]
}
