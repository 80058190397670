










import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import { defaultProfile } from '@/config/default/defaultImages'
import { baseURL } from '@/api/requests/httpAxios'

@Component
export default class AvatarRenderer extends Vue {
  params!: ICellRendererParams

  private defaultProfile_ = defaultProfile
  private baseURL = baseURL
  private get avatar() {
    return this.params.value ? `${baseURL}${this.params.value}` : defaultProfile
  }
}
