import { defineAbility } from '@casl/ability'
import { projectStatus } from '@/config/projects/status'
import { PermissionManager } from '@/api/permissions/PermissionManager'
import { applicationStatus } from '@/config/projects/applicationStatus'
import { projectRoles } from '@/config/projects/projectRoles'

export enum permissionsProject {
  editProject = 'editProject',
  viewProject = 'viewProject',

  editTasks = 'editTasks',
  moveTasks = 'moveTasks'
}

const permissionMatrix = {
  projectStatus: {
    [projectStatus.draft]: {
      isAuthed: [],
      isAdmin: [Object.values(permissionsProject)],
      isProjectParticipant: [],
      isAuthorProject: [Object.values(permissionsProject)],
      isLeaderProject: [Object.values(permissionsProject)]
    },
    [projectStatus.application]: {
      isAuthed: [permissionsProject.viewProject],
      isAdmin: [Object.values(permissionsProject)],
      isProjectParticipant: [
        permissionsProject.viewProject,
        permissionsProject.moveTasks
      ],
      isAuthorProject: [Object.values(permissionsProject)],
      isLeaderProject: [Object.values(permissionsProject)]
    },
    [projectStatus.confirmed]: {
      isAuthed: [permissionsProject.viewProject],
      isAdmin: [Object.values(permissionsProject)],
      isProjectParticipant: [
        permissionsProject.viewProject,
        permissionsProject.moveTasks
      ],
      isAuthorProject: [Object.values(permissionsProject)],
      isLeaderProject: [Object.values(permissionsProject)]
    }
  }
} as any

export class ProjectPermissions {
  public static project(project: any) {
    return defineAbility(can => {
      const userId = PermissionManager.user('currentUser').id
      const isAdmin = PermissionManager.user('isAdmin')
      const isProjectParticipant =
        project?.users?.find(
          (user: any) =>
            user.id === userId &&
            user.ProjectParticipant?.projectRoles?.includes(
              projectRoles.participant
            ) &&
            user.ProjectParticipant?.applicationStatus ===
              applicationStatus.accepted
        ) || false

      const isAuthorProject =
        project?.users?.find(
          (user: any) =>
            user.id === userId &&
            user.ProjectParticipant?.projectRoles?.includes(projectRoles.author)
        ) || false

      const isLeaderProject =
        project?.users?.find(
          (user: any) =>
            user.id === userId &&
            user.ProjectParticipant?.projectRoles?.includes(projectRoles.leader)
        ) || false

      const mapKeys: { [k: string]: boolean } = {
        isAuthed: userId,
        isAdmin: isAdmin,
        isProjectParticipant,
        isAuthorProject,
        isLeaderProject
      }

      const permissionsList =
        permissionMatrix.projectStatus[
          project.status || projectStatus.application
        ]

      for (const [key, permissions] of Object.entries(permissionsList)) {
        if (mapKeys[key]) {
          for (const permission of permissions as string[]) {
            can(permission, undefined)
          }
        }
      }
    })
  }
}
